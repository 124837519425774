import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = [
    "fileInput",
    "previewContainer",
    "deletedPhotosField",
    "placeholderTemplate",
    "imageTemplate",
    "errorContainer"
  ];

  static values = {
    parentClass: String,
    parentId: String,
    photoClass: String,
    temporaryId: String,
    isRequired: Boolean
  };

  connect() {
    console.log("ImagesUploadController connected");
    console.log("Temporary  ID:", this.temporaryIdValue);

    this.uploadedPhotos = [];
    this.deletedPhotos = [];
    this.initSortable();
    this.updateRequiredAttribute();
  }

  handleFileChange(event) {
    if (!this.temporaryIdValue) {
      this.showError("Error: Temporary identifier is not set.");
      return;
    }

    const allowedExtensions = ["png", "jpg", "jpeg", "avif"];
    const files = Array.from(event.target.files);

    files.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        this.showError(`Unsupported file format: ${file.name}. Please upload PNG, JPG, JPEG or AVIF files.`);
        return;
      }

      this.uploadFile(file);
    });

    this.fileInputTarget.value = "";
  }

  uploadFile(file) {
    const placeholder = this.createPlaceholder();

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("parent_class", this.parentClassValue);
    formData.append("photo_class", this.photoClassValue);
    formData.append("temporary_id", this.temporaryIdValue);

    if (this.parentIdValue) {
      formData.append("parent_id", this.parentIdValue);
    }

    fetch("/photos/upload", {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error || `Server responded with status ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.url) {
          this.replacePlaceholder(placeholder, data.id, data.url);
          this.uploadedPhotos.push(data.id);
        } else {
          placeholder.remove();
          this.showError("Upload error: server did not return an image URL.");
        }
      })
      .catch((error) => {
        placeholder.remove();
        this.showError(error);
      });
  }

  createPlaceholder() {
    if (!this.hasPlaceholderTemplateTarget) {
      console.error("Placeholder template not found");
      return;
    }

    const placeholder = this.placeholderTemplateTarget.content.cloneNode(true).firstElementChild;
    this.previewContainerTarget.appendChild(placeholder);

    return placeholder;
  }

  replacePlaceholder(placeholder, id, url) {
    const template = this.imageTemplateTarget;
    const clone = template.content.cloneNode(true);

    // Create an Image object for preloading
    const img = new Image();
    img.src = url;

    img.onload = () => {
      clone.querySelector("[data-photo-id]").dataset.photoId = id;
      const imgElement = clone.querySelector("[data-img-src]");
      imgElement.src = url;

      imgElement.classList.add("opacity-0", "transition-opacity", "duration-300", "ease-in-out");

      const deleteButton = clone.querySelector("button");
      deleteButton.dataset.photoIdValue = id;
      deleteButton.addEventListener("click", (event) => this.markForDeletion(event));

      placeholder.replaceWith(clone);

      // Smooth transition: wait for a frame for `opacity-0` to apply
      requestAnimationFrame(() => {
        setTimeout(() => {
          imgElement.classList.remove("opacity-0");
          imgElement.classList.add("opacity-100");
        }, 50); // in ms

        this.updateRequiredAttribute();
      });
    };


    img.onerror = () => {
      console.error("Image load error:", url);
      placeholder.remove();
    };
  }

  markForDeletion(event) {
    const button = event.currentTarget;
    const photoId = button.dataset.photoIdValue;

    if (!photoId) {
      console.error("No photo ID found on the button.");
      return;
    }

    if (!this.deletedPhotos.includes(photoId)) {
      this.deletedPhotos.push(photoId);
      this.updateDeletedPhotosField();
    }

    const photoElement = button.closest("[data-photo-id]");

    if (photoElement) {
      photoElement.remove();
      this.updateRequiredAttribute();
    }
  }

  updateDeletedPhotosField() {
    const jsonValue = JSON.stringify(this.deletedPhotos);
    this.deletedPhotosFieldTarget.value = jsonValue;
  }

  initSortable() {
    if (this.previewContainerTarget) {
      new Sortable(this.previewContainerTarget, {
        animation: 150,
        ghostClass: "sortable-ghost",
        onEnd: () => {
          this.updatePhotoOrder();
        },
      });
    }
  }

  updatePhotoOrder() {
    const orderedIds = Array.from(this.previewContainerTarget.children).map(
      (child) => child.dataset.photoId
    );

    fetch("/photos/sort", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
      body: JSON.stringify({
        order: orderedIds,
        parent_class: this.parentClassValue,
        photo_class: this.photoClassValue
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update photo order");
        }
      })
      .catch((error) => {
        this.showError("Error updating photo order: " + error);
      });
  }

  updateRequiredAttribute() {
    const hasPhotos = Array.from(this.previewContainerTarget.children).some(
      (child) => child.hasAttribute("data-photo-id")
    );

    if (this.isRequiredValue) {
      if (hasPhotos) {
        this.fileInputTarget.removeAttribute("required");
      } else {
        this.fileInputTarget.setAttribute("required", "required");
      }
    }
  }

  showError(message) {
    if (!this.hasErrorContainerTarget) {
      console.error("Error container not found");
      return;
    }

    this.errorContainerTarget.textContent = message;
    this.errorContainerTarget.classList.remove("hidden");

    setTimeout(() => {
      this.errorContainerTarget.classList.add("hidden");
    }, 10000);
  }
}
